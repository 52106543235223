import urbanismEndpoints from "./endpoints/urbanism-endpoints";
import apiConfig from "../config/api";
import http from "./http";
import store from "../store/index";

const {
  getDocuments,
  getDocument,
  addDocument,
  editDocument,
  deleteDocument
} = urbanismEndpoints;
const { baseURL } = apiConfig;

const Urbanism = {
  async getDocuments() {
    const getDocumentsURL = `${baseURL}${getDocuments.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getDocumentsURL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async editDocument(document) {
      const getEditDocumentUrl = `${baseURL}${editDocument.GET(document.id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .put(
        getEditDocumentUrl,
        {
          id: document.id,
          applicantName: document.applicantName,
          purpose: document.purpose,
          documentType: document.documentType,
          registrationDate: document.registrationDate,
          expirationDate: document.expirationDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async addDocument(document) {
    const getAddDocumentUrl = `${baseURL}${addDocument.GET}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .post(
        getAddDocumentUrl,
        {
          applicantName: document.applicantName,
          purpose: document.purpose,
          documentType: document.documentType,
          registrationDate: document.registrationDate,
          expirationDate: document.expirationDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async getDocument(id) {
    const getDocumentURL = `${baseURL}${getDocument.GET(id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getDocumentURL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async deleteDocument(id) {
    const getDeleteDocumentUrl = `${baseURL}${deleteDocument.GET(id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .delete(
        getDeleteDocumentUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  }
};

export default Urbanism;
