export default Object.freeze({
  getDocuments: {
    GET: () => {
      return `urbanism/documents`;
    },
  },
  addDocument: {
    GET: "urbanism",
  },
  editDocument: {
    GET: (id) => {
      return `urbanism/${id}`;
    },
  },
  getDocument: {
    GET: (id) => {
      return `urbanism/${id}`;
    },
  },
  deleteDocument: {
    GET: (id) => {
      return `urbanism/${id}`;
    },
  }
});
