<template>
  <div>
    <ValidationObserver ref="form">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Informatii document</p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    name="Tip document"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Tip document'"
                      :message="errors[0]"
                    >
                      <b-dropdown
                        v-model="selectedDocumentType"
                        aria-role="list"
                        :disabled="editMode == true"
                      >
                        <template #trigger>
                          <b-button type="is-primary" icon-right="menu-down">
                            Tip document: {{ selectedDocumentType.name }}
                          </b-button>
                        </template>
                        <b-dropdown-item
                          v-for="option in documentTypes"
                          :key="option.id"
                          :value="option.name"
                          @click="selectedDocumentType = option"
                        >
                          {{ option.name }}
                        </b-dropdown-item>
                        <!-- 
                        <b-dropdown-item value="0" aria-role="listitem">
                          <span>Certificat</span>
                        </b-dropdown-item> -->
                      </b-dropdown>
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    name="Nume"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Nume solicitant'"
                      :message="errors[0]"
                    >
                      <b-input
                        class="user-name-input"
                        v-model="document.applicantName"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    name="Scop"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Scop'"
                      :message="errors[0]"
                    >
                      <b-input
                        class="user-name-input"
                        v-model="document.purpose"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Data eliberare</label>
                  <div class="control">
                    <b-datepicker
                      :date-parser="parser"
                      v-model="document.registrationDate"
                      @input="registrationDateSelected()"
                      locale="ro"
                      :unselectable-days-of-week="[0, 6]"
                    >
                    </b-datepicker>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Data expirare</label>
                  <div class="control">
                    <b-datepicker
                      :date-parser="parser"
                      v-model="document.expirationDate"
                      locale="ro"
                      :unselectable-days-of-week="[0, 6]"
                    >
                    </b-datepicker>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field grouped>
                  <div class="control">
                    <b-button
                      type="is-primary"
                      native-type="submit"
                      @click="addDocument"
                      >Salvare</b-button
                    >
                  </div>
                  <div class="control">
                    <b-button type="is-danger is-outlined" @click="cancel()"
                      >Anulare</b-button
                    >
                  </div>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { rawObject } from "@/helpers/utils";
import { pathOr } from "ramda";
import Urbanism from "../../services/urbanism.service";
import moment from "moment";

export default {
  name: "UrbanismDocumentAdd",
  async created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    this.getDocumentTypes();
    if (id) {
      this.editMode = true;
      this.documentId = id;
      await this.getDocument(this.documentId);

      if (this.document.registrationDate) {
        this.document.registrationDate = new Date(
          this.document.registrationDate
        );
      }
      if (this.document.expirationDate) {
        this.document.expirationDate = new Date(this.document.expirationDate);
      }
    } else {
      this.document.registrationDate = new Date();
      this.document.expirationDate = new Date(
        moment(this.document.registrationDate, "DD-MM-YYYY").add(1, "years")
      );
    }
  },
  data() {
    return {
      document: {
        registrationDate: null,
        expirationDate: null,
        applicantName: null,
        purpose: null,
        documentTypes: [],
        selectedDocumentType: {},
      },
      documentId: 0,
      editMode: false,
    };
  },
  methods: {
    getDocumentTypes() {
      this.documentTypes = [
        {
          id: 0,
          name: "Certificat",
        },
        {
          id: 1,
          name: "Autorizatie",
        },
      ];

      this.selectedDocumentType = this.documentTypes[0];
    },
    registrationDateSelected() {
      this.document.expirationDate = new Date(
        moment(this.document.registrationDate, "DD-MM-YYYY").add(1, "years")
      );
    },
    parser(d) {
      return new Date(Date.parse(d));
    },
    addDocument() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.document.documentType = this.selectedDocumentType.id;
        
        if (this.editMode) {
          this.$emit("handleEditDocument", rawObject(this.document))
        } else {
          this.$emit("handleAddDocument", rawObject(this.document));
        }
      });
    },
    async getDocument(id) {
      const { getDocument } = Urbanism;
      const response = await getDocument(id);
      const { data } = response;
      this.document = data;
    },
    cancel() {
      this.$router.push({ path: "/Urbanism" });
    },
  },
};

</script>
