<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <card-component
        :title="formCardTitle"
        icon="file-edit"
        class="tile is-child"
      >
        <urbanism-document-add
          @handleAddDocument="handleAddDocument"
          @handleEditDocument="handleEditDocument"
        />
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from "../components/TitleBar.vue";
import CardComponent from "@/components/CardComponent";
import { pathOr } from "ramda";
import UrbanismDocumentAdd from "../components/urbanism/urbanismdocument-add.vue";
import Urbanism from "../services/urbanism.service";

export default {
  components: { TitleBar, UrbanismDocumentAdd, CardComponent },
  name: "UrbanismDocument",
  data() {
    return {
      document: {},
      documentId: 0,
      editMode: false,
    };
  },
  computed: {
    titleStack() {
      if (this.editMode) {
        return ["Registru Urbanism", "Editare document"];
      } else {
        return ["Registru Urbanism", "Adaugare document"];
      }
    },
    formCardTitle() {
      if (this.editMode) {
        return "Editare document";
      } else {
        return "Adaugare document";
      }
    },
  },
  methods: {
    async handleAddDocument(document) {
      const { addDocument } = Urbanism;
      const response = await addDocument(document);
      const { data, status } = response;
      if (status == "200") {
        this.$buefy.snackbar.open({
          message: "Inregistrarea a fost adaugata.",
          queue: false,
        });
      }
      this.$router.push({
        path: `/Urbanism`,
      });
    },
    async handleEditDocument(document) {
      const { editDocument } = Urbanism;
      const response = await editDocument(document);
      const { data, status } = response;
      if (status == "200") {
        this.$buefy.snackbar.open({
          message: "Inregistrarea a fost modificata.",
          queue: false,
        })
      }
      else{
        this.$buefy.snackbar.open({
          message: 'Eroare la editarea inregistrarii',
          queue: false
        })
      }
      this.$router.push({ path: "/Urbanism" });
    },
  },
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    if (id) {
      this.editMode = true;
    }
    this.documentId = id;
  },
};
</script>

<style></style>
